import { RootState } from 'app/store';
import { MatchesState } from 'redux/reducers/matches';
import { createSelector } from 'reselect';

export const matchesSelector = (state: RootState): MatchesState => state.matches;

export const allMatchesSelector = createSelector(
  matchesSelector,
  (match) => match.matches
);

export const matchesTotalSelector = createSelector(
  matchesSelector,
  (match) => match.total
);

export const selectedMatchSelector = createSelector(
  matchesSelector,
  (match) => match.selectedMatch
);

export const matchesLoadingSelector = createSelector(
  matchesSelector,
  (match) => match.loading
);

export const matchesErrorSelector = createSelector(
  matchesSelector,
  (match) => match.error
);

export const matchesReviewSelector = createSelector(
  matchesSelector,
  (match) => match.matchesReview
);
