import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { Match, MatchReview, MatchStatus } from 'models/match';
import {
  CreateMatchRequest,
  CreateMatchResponse,
  DeleteMatchRequest,
  GetMatchByIdRequest,
  GetMatchByIdResponse,
  GetMatchesRequest,
  GetMatchesResponse,
  IgnoreMatchRequest,
  MatchReviewResponse,
  UpdateMatchRequest,
  UpdateMatchResponse,
} from 'types/matches';

export interface MatchesState {
  matches: Match[];
  selectedMatch: Match | null;
  matchesReview: MatchReview[];
  total: number | null;
  offset: number;
  loading: boolean;
  error: any;
}

const initialState: MatchesState = {
  matches: [],
  matchesReview: [],
  selectedMatch: null,
  loading: false,
  total: null,
  offset: 0,
  error: null,
};

const matchesSlice = createSlice({
  name: 'matches',
  initialState: initialState,
  reducers: {
    getMatches(state: MatchesState, action: PayloadAction<GetMatchesRequest>) {
      state.loading = true;
    },
    getMatchesSuccess(state: MatchesState, action: PayloadAction<GetMatchesResponse>) {
      state.loading = false;
      if (state.offset > 0) {
        state.matches = [...state.matches, ...action.payload.data];
      } else {
        state.matches = action.payload.data;
      }
      state.total = action.payload.total;
    },
    getMatchesFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getMatchById(state: MatchesState, action: PayloadAction<GetMatchByIdRequest>) {
      state.loading = true;
    },
    getMatchByIdSuccess(
      state: MatchesState,
      action: PayloadAction<GetMatchByIdResponse>
    ) {
      state.loading = false;
      state.selectedMatch = action.payload;
    },
    getMatchByIdFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    createMatch(state: MatchesState, action: PayloadAction<CreateMatchRequest>) {
      state.loading = true;
    },
    createMatchSuccess(state: MatchesState, action: PayloadAction<CreateMatchResponse>) {
      state.loading = false;
      state.matches.push(action.payload);
    },
    createMatchFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateMatch(state: MatchesState, action: PayloadAction<UpdateMatchRequest>) {
      state.loading = true;
    },
    updateMatchSuccess(state: MatchesState, action: PayloadAction<UpdateMatchResponse>) {
      state.loading = false;
      state.matches = state.matches.map((match) =>
        match._id === action.payload._id ? { ...match, ...action.payload } : match
      );
    },
    updateMatchFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteMatch(state: MatchesState, action: PayloadAction<DeleteMatchRequest>) {
      state.loading = true;
    },
    deleteMatchSuccess(state: MatchesState, action: PayloadAction<DeleteMatchRequest>) {
      state.loading = false;
      state.matches = state.matches.filter((match) => match._id !== action.payload.id);
    },
    deleteMatchFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    loadQueueMatches(state: MatchesState) {
      state.loading = true;
    },
    loadQueueMatchesSuccess(state: MatchesState) {
      state.loading = false;
    },
    loadQueueMatchesFailed(state: MatchesState, action: PayloadAction<any>) {
      state.error = action.payload;
    },
    publishAllMatches(state: MatchesState) {
      state.loading = true;
    },
    publishAllMatchesSuccess(state: MatchesState) {
      state.loading = false;
      state.matches = state.matches.map((match) => {
        if (match.status === MatchStatus.QUEUED) {
          match.status = MatchStatus.LIVE;
        }
        return match;
      });
    },
    publishAllMatchesFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    ignoreMatch(state: MatchesState, action: PayloadAction<IgnoreMatchRequest>) {
      state.loading = true;
    },
    ignoreMatchSuccess(state: MatchesState, action: PayloadAction<IgnoreMatchRequest>) {
      state.loading = false;
      state.matches = state.matches.map((match) =>
        match._id === action.payload.id
          ? { ...match, status: MatchStatus.IGNORED }
          : match
      );
    },
    ignoreMatchFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getMatchesReview(state: MatchesState) {
      state.loading = true;
    },
    getMatchesReviewSuccess(
      state: MatchesState,
      action: PayloadAction<MatchReviewResponse>
    ) {
      state.loading = false;
      state.matchesReview = action.payload;
    },
    getMatchesReviewFailed(state: MatchesState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: MatchesState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const matchesActions = matchesSlice.actions;

// Reducer
const matchesReducer = matchesSlice.reducer;
export default matchesReducer;
