import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

type Props = {
  label: string;
  selected?: string[];
  onChange: (value: string[]) => void;
  sx?: SxProps<Theme>;
  name?: string;
  error?: boolean;
  errorText?: string;
};

const isValidEmail = (email: string) => {
  // Basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const EmailInputSelect: React.FC<Props> = ({
  onChange,
  label,
  sx,
  selected = [],
  error = false,
  errorText,
}) => {
  console.log('selected', selected);
  const [inputValue, setInputValue] = useState('');
  const [emails, setEmails] = useState<string[]>(selected);

  console.log('emails', emails);

  // Update emails state when selected prop changes
  useEffect(() => {
    if (selected) {
      setEmails(selected);
    }
  }, [selected]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue) {
      event.preventDefault();
      if (isValidEmail(inputValue)) {
        setEmails((prevEmails) => {
          const updatedEmails = [...prevEmails, inputValue];
          onChange(updatedEmails);
          return updatedEmails;
        });
        setInputValue('');
      }
    }
  };

  const handleDelete = (emailToDelete: string) => {
    setEmails((prevEmails) => {
      const updatedEmails = prevEmails.filter((email) => email !== emailToDelete);
      onChange(updatedEmails);
      return updatedEmails;
    });
  };

  return (
    <>
      <TextField
        label={label}
        sx={sx}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        error={error}
        helperText={error ? errorText : ''}
        placeholder="Enter email and press Enter"
      />
      <div style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
        {emails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={() => handleDelete(email)}
            sx={{
              marginRight: '5px',
              marginBottom: '5px',
              backgroundColor: 'transparent',
              border: '1px solid rgba(30, 30, 112, 0.05)',
            }}
          />
        ))}
      </div>
      {error && (
        <Typography sx={{ margin: '-10px 0px 0 14px' }} color="error" variant="caption">
          {errorText || ''}
        </Typography>
      )}
    </>
  );
};

export default EmailInputSelect;
