import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CustomSelect, Page } from 'components';
import HomeDateRangeFilter, { DateRange } from './homeDateRangeFilter';
import { DateFilterOption } from 'types/experiences';
import moment from 'moment';
import { SelectOptionType } from 'components/common/CustomSelect';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_START_DATE, GRAFANA_BASE_URL } from 'constans/dashboard';
import { allCitiesSelector } from 'redux/selectors/cities';
import { citiesActions } from 'redux/reducers/cities';
import { countriesSelector } from 'redux/selectors/app';
import { appActions } from 'redux/reducers/app';
import CustomAutocomplete from 'components/common/CustomAutocomplete';
import { isArray } from 'lodash';
import { weeklyAnalyticsSelector } from 'redux/selectors/analytics';
import { analyticsActions } from 'redux/reducers/analytics';
import { determineChangeStatus } from 'utils/analytics';
import PercentageDiff from './usersAnalytics/percentageDiff';

const FILTER_OPTIONS: DateFilterOption[] = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'yesterday',
    label: 'Yesterday',
  },
  {
    key: 'week',
    label: 'This week',
  },
  {
    key: 'all',
    label: 'All',
  },
];

const membershipOptions: SelectOptionType[] = [
  { value: 'All', label: 'All' },
  {
    value: process.env.REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PRODUCT_ID as string,
    label: 'Y Pass',
  },
  {
    value: process.env.REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PLUS_PRODUCT_ID as string,
    label: 'Y Pass+',
  },
  {
    value: process.env
      .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_UNLIMITED_PRODUCT_ID as string,
    label: 'Y Pass Unlimited',
  },
  { value: 'other', label: 'Other' },
];

const Home: React.FC = () => {
  const [membershipFilter, setMembershipFilter] = useState('All');
  const [countryFilter, setCountryFilter] = useState('All');
  const [cityFilter, setCityFilter] = useState('All');
  const [dateFilter, setDateFilter] = useState<DateRange>();
  const cities = useAppSelector(allCitiesSelector);
  const countries = useAppSelector(countriesSelector);
  const weeklyAnalytics = useAppSelector(weeklyAnalyticsSelector);

  const dispatch = useAppDispatch();

  const today = moment().valueOf();

  useEffect(() => {
    if (!cities?.length) {
      dispatch(citiesActions.getAllCities());
    }
  }, [cities]);

  useEffect(() => {
    if (!countries?.length) {
      dispatch(appActions.getAllCountries());
    }
  }, [countries]);

  useEffect(() => {
    if (!weeklyAnalytics) {
      dispatch(analyticsActions.getWeeklyAnalytics());
    }
  }, [weeklyAnalytics]);

  const handleDateRangeChange = (range: DateRange) => {
    setDateFilter(range);
  };

  const citiesOptions = useMemo(() => {
    const options =
      cities?.length > 0
        ? cities.map((c) => ({
            value: c.name,
            label: c.name,
          }))
        : [];
    return [
      { value: 'All', label: 'All' },
      ...options.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [cities, cityFilter]);

  const countriesOptions = useMemo(() => {
    const options =
      countries?.length > 0
        ? countries.map((c) => ({
            value: c.code,
            label: c.name,
          }))
        : [];
    return [
      { value: 'All', label: 'All' },
      ...options.sort((a, b) => a.label.localeCompare(b.label)),
    ];
  }, [countries]);

  const frameUrl = useMemo(
    () =>
      `${GRAFANA_BASE_URL}&from=${dateFilter?.startDate || DEFAULT_START_DATE}&to=${
        dateFilter?.endDate || today
      }&var-status=All&var-subscriptionProductId=${membershipFilter}&var-country=${countryFilter}&var-city=${cityFilter}&theme=light`,
    [dateFilter, membershipFilter, countryFilter, cityFilter]
  );

  return (
    <Page title="Dashboard" className="home-page">
      <a
        style={{ display: 'inline-flex', marginBottom: '29px' }}
        href="https://grafana.yayem.co/d/e7bf7b27-db17-48fb-9a84-1b1442401a13/users-in-time?orgId=1"
        target="_blank"
      >
        Dashboard detailed info (Grafana)
      </a>
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{ marginBottom: '24px' }}
      >
        <Stack flexDirection={'row'}>
          <CustomSelect
            sx={{ width: 150, marginRight: '14px' }}
            name="membership"
            label="Membership"
            options={membershipOptions}
            value={membershipFilter}
            onChange={(e) => {
              setMembershipFilter(e.target.value);
            }}
          />
          <CustomAutocomplete
            label="Country"
            sx={{ width: 200, marginRight: '14px' }}
            options={countriesOptions}
            value={countryFilter}
            onChange={(selected) => {
              if (!isArray(selected)) setCountryFilter(selected);
            }}
          />
          <CustomAutocomplete
            label="City"
            sx={{ width: 200, marginRight: '14px' }}
            options={citiesOptions}
            value={cityFilter}
            onChange={(selected) => {
              if (!isArray(selected)) setCityFilter(selected);
            }}
          />
        </Stack>
        <HomeDateRangeFilter
          maxDate={moment().toDate()}
          onDateRangeChange={handleDateRangeChange}
          defaultOption={FILTER_OPTIONS[FILTER_OPTIONS.length - 1]}
          options={FILTER_OPTIONS}
        />
      </Stack>
      <Grid container sx={{ marginBottom: '24px' }} spacing={2}>
        <Grid item xs={6}>
          <Stack sx={{ marginBottom: '24px', position: 'relative' }}>
            <iframe
              src={`${frameUrl}&panelId=7`}
              width="100%"
              height="200"
              style={{ border: 0 }}
            ></iframe>
            {weeklyAnalytics && (
              <PercentageDiff
                sx={{ left: '90px' }}
                {...determineChangeStatus(weeklyAnalytics?.members)}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack sx={{ marginBottom: '24px', position: 'relative' }}>
            <iframe
              src={`${frameUrl}&panelId=8`}
              width="100%"
              height="200"
              style={{ border: 0 }}
            ></iframe>
            {weeklyAnalytics && (
              <PercentageDiff
                sx={{ left: '80px' }}
                {...determineChangeStatus(weeklyAnalytics.pipeline)}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=5`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=6`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=2`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=4`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack sx={{ marginBottom: '24px', position: 'relative' }}>
            <iframe
              src={`${frameUrl}&panelId=3`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=9`}
              width="100%"
              height="300"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=10`}
              width="100%"
              height="300"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=11`}
              width="100%"
              height="224"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=12`}
              width="100%"
              height="300"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '24px' }}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=1`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
            <span>Click each status to view a more detailed chart</span>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=13`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=14`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=15`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=16`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=17`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=18`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=19`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=20`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <iframe
              src={`${frameUrl}&panelId=21`}
              width="100%"
              height="524"
              style={{ border: 0 }}
            ></iframe>
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Home;
