import { Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import { FC, useEffect, useState } from 'react';
import { matchesActions } from 'redux/reducers/matches';
import {
  allMatchesSelector,
  matchesLoadingSelector,
  matchesTotalSelector,
} from 'redux/selectors/matches';
import MatchesTable from './matchesTable/matchesTable';
import MatchesFilters, { StatusOption } from './matchesTable/matchesFilters';
import { useDidUpdateEffect } from 'utils/hooks';
import { MatchStatus } from 'models/match';
import { useNavigate } from 'react-router-dom';

const Matches: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const matches = useAppSelector(allMatchesSelector);
  const total = useAppSelector(matchesTotalSelector);

  const loading = useAppSelector(matchesLoadingSelector);
  const [offset, setOffset] = useState(0);
  const [selectedStatusOption, setSelectedStatusOption] = useState<StatusOption>('all');

  useEffect(() => {
    if (!matches?.length) {
      setOffset(0);
      dispatch(matchesActions.getMatches({}));
    }
    return () => {
      dispatch(matchesActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      matchesActions.getMatches({
        status:
          selectedStatusOption === 'all' ? ('' as MatchStatus) : selectedStatusOption,
        offset: 0,
      })
    );
  }, [selectedStatusOption]);

  const loadMore = () => {
    dispatch(
      matchesActions.getMatches({
        status:
          selectedStatusOption === 'all' ? ('' as MatchStatus) : selectedStatusOption,
        offset: offset + 10,
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(matchesActions.updateOffset({ offset: offset }));
  }, [offset]);

  return (
    <Page title="Tags">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="100%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
            <MatchesFilters
              status={selectedStatusOption}
              onStatusChange={setSelectedStatusOption}
              onPublishClick={() => {
                dispatch(matchesActions.publishAllMatches());
              }}
              onLoadQueueClick={() => {
                dispatch(matchesActions.loadQueueMatches());
              }}
            />
          </Stack>
          <MatchesTable
            total={total || 0}
            loadMore={loadMore}
            loading={loading}
            matches={matches || []}
            onDelete={(id: string) => dispatch(matchesActions.deleteMatch({ id }))}
            onEdit={(id: string) => navigate(`/matches/edit/${id}`)}
            onIgnore={(id: string) => dispatch(matchesActions.ignoreMatch({ id }))}
          />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default Matches;
