import {
  ExperiencePricing,
  ExperiencePricingValues,
  PricingTypeEnum,
} from 'models/experiences';
import { SubscriptionSettings, SubscriptionVisistsEnum } from 'models/place';

export const getDefaultSubscriptionSettingsForPlaces = (): SubscriptionSettings[] => {
  return [
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.NONE,
    },
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PLUS_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.SPECIFIC_AMOUNT,
      visitsRestrictionAmount: 24,
    },
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_UNLIMITED_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.UNLIMITED,
    },
  ];
};

export const getDefaultSubscriptionPricesForExperiencesPlaces = (
  pricing: ExperiencePricing[] | [] | undefined
): ExperiencePricingValues[] => {
  const defaultPrices = [
    {
      type: PricingTypeEnum.STANDARD,
      subscriptionId: '',
    },
    {
      type: PricingTypeEnum.SUBSCRIPTION,
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PRODUCT_ID as string,
    },
    {
      type: PricingTypeEnum.SUBSCRIPTION,
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PLUS_PRODUCT_ID as string,
    },
    {
      type: PricingTypeEnum.SUBSCRIPTION,
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_UNLIMITED_PRODUCT_ID as string,
    },
  ];

  if (!pricing || pricing.length === 0) {
    return defaultPrices;
  } else {
    const prices = defaultPrices.map((price) => {
      if (price.type === PricingTypeEnum.STANDARD) {
        const exisitngPrice = pricing.find((p) => p.type === PricingTypeEnum.STANDARD);
        console.log('exisitngPrice', exisitngPrice);
        return {
          ...exisitngPrice,
          public: exisitngPrice?.public ? (exisitngPrice?.public / 100).toString() : '',
          private: exisitngPrice?.private
            ? (exisitngPrice?.private / 100).toString()
            : '',
          ...price,
        };
      } else {
        const exisitngPrice = pricing.find(
          (p) => p.subscriptionId === price.subscriptionId
        );
        console.log('exisitngPrice', exisitngPrice);
        return {
          ...exisitngPrice,
          public: exisitngPrice?.public ? (exisitngPrice?.public / 100).toString() : '',
          private: exisitngPrice?.private
            ? (exisitngPrice?.private / 100).toString()
            : '',
          ...price,
        };
      }
    });

    console.log('prices', prices);

    return prices;
  }
};
