import { Box, Button, Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ContextMenu, ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import { Match } from 'models/match';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

type Props = {
  loading?: boolean;
  matches: Match[];
  total: number;
  loadMore: () => void;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onIgnore: (id: string) => void;
};

const MatchesTable: FC<Props> = ({
  loading,
  matches,
  total,
  loadMore,
  onDelete,
  onEdit,
  onIgnore,
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !matches.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>User 1</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>User 2</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>Common Tags</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>Creator</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>Message</TableSortLabel>
                </StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {matches.map((match) => (
                <StyledTableRow key={match._id} className="table-row">
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {`${match.user1.profile.firstName}(${match.user1.email})`}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {`${match.user2.profile.firstName}(${match.user2.email})`}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {match.commonTags.map((tag) => tag.value).join(', ')}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {match.createdBy}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {match.message}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    <Box className={`match-status-badge ${match.status}`}>
                      <Typography className="status-badge-label" variant="body2">
                        {match.status}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ContextMenu
                      options={[
                        { label: 'Edit match', onClick: () => onEdit(match._id) },
                        { label: 'Ignore match', onClick: () => onIgnore(match._id) },
                        { label: 'Delete match', onClick: () => onDelete(match._id) },
                      ]}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {total && matches?.length < total ? (
        <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
          Load more
        </Button>
      ) : null}
    </Paper>
  );
};

export default MatchesTable;
